<template>
    <Modal :modal-title="title"
           :dialog="questionFormVisibility"
           max-width="1000px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageQuestionForm ref="form"
                                :is-editable="isEditable"></ManageQuestionForm>

            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout justify-end
                    class="mt-3 full-width">
                <v-flex md6>
                    <v-layout align-center
                              justify-end>
                        <v-btn color="secondary"
                               outlined
                               class="mr-3"
                               data-test="closeBtn"
                               @click="close">{{ $t('buttons.cancel') }}</v-btn>
                        <v-btn color="secondary"
                               data-test="saveBtn"
                               :loading="loader"
                               :disabled="loader"
                               @click="save">{{ $t('buttons.save') }}</v-btn>
                        <v-btn class="ml-3"
                               color="secondary"
                               data-test="saveAndAddBtn"
                               :loading="addLoader"
                               :disabled="addLoader"
                               @click="saveAndAddNext">{{ $t('buttons.save_and_add_next') }}</v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageQuestionForm from '@/components/templatesConfigurations/manageQuestion/ManageQuestionForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageQuestion',
    components: { Modal, ManageQuestionForm, ConfirmationDialog, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false,
        addLoader: false
    }),
    computed: {
        ...mapState('manageTemplateQuestion', [
            'questionFormVisibility',
            'editedQuestionId'
        ]),
        ...mapState('manageTemplate', [
            'editedTemplateId',
            'activeTemplate'
        ]),
        title () {
            return this.isEditable
                ? this.$t('configurations.template_questions.edit_title')
                : this.$t('configurations.template_questions.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEditable () {
            return this.editedQuestionId !== null;
        }
    },
    methods: {
        close () {
            this.$store.commit('manageTemplateQuestion/SET_QUESTION_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('manageTemplateQuestion/RESET_QUESTION_DATA');
                if (this.isEditable) {
                    this.$store.commit('manageTemplateQuestion/SET_EDITED_QUESTION_ID', null);
                }
                this.showError = false;
            }, 100);
        },
        visibility (event) {
            this.$store.commit('manageTemplateQuestion/SET_QUESTION_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createAction () {
            return this.$store.dispatch('manageTemplate/addQuestion');
        },
        createQuestion () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.createAction()
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        createAndAddNextQuestion () {
            this.addLoader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.createAction()
                            .then(() => {
                                this.$store.commit('manageTemplateQuestion/RESET_QUESTION_DATA');
                                this.$refs.form.setInitialValues();
                                this.showError = false;
                                this.$refs.form.resetValidation();
                            })
                            .finally(() => {
                                this.addLoader = false;
                            });
                    } else {
                        this.addLoader = false;
                    }
                });
        },
        updateAction () {
            return this.$store.dispatch('manageTemplate/editQuestion');
        },
        updateQuestion () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.updateAction()
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateAndAddNextQuestion () {
            this.addLoader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.updateAction()
                            .then(() => {
                                this.$store.commit('manageTemplateQuestion/RESET_QUESTION_DATA');
                                this.$store.commit('manageTemplateQuestion/SET_EDITED_QUESTION_ID', null);
                                this.$refs.form.setInitialValues();
                                this.showError = false;
                                this.$refs.form.resetValidation();
                            })
                            .finally(() => {
                                this.addLoader = false;
                            });
                    } else {
                        this.addLoader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateQuestion();
            } else {
                this.createQuestion();
            }
        },
        saveAndAddNext () {
            if (this.isEditable) {
                this.updateAndAddNextQuestion();
            } else {
                this.createAndAddNextQuestion();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
