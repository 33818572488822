<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="d-inline-block">
        <HintingIcon :icon="ICONS.ADD_EMPLOYEE"
                     @click="dialog = true"
                     :tooltip="$t('labels.templates_persons')"></HintingIcon>

        <Modal :modal-title="$t('labels.templates_persons') + ' ' + name"
               :dialog="dialog"
               max-width="1200px"
               @close="dialog = false"
               @visibility="dialog = $event">
                <template slot="content">
                    <v-card min-height="50vh">
                    <DefaultTable :setup="tableSetup" ref="employeesTable">
                        <template #search>
                            <v-col cols="12" md="6">
                                <Autocomplete :label="$t('navigation.persons_groups')"
                                              :items="filterableEmployeesGroups"
                                              item-text="text"
                                              item-value="value"
                                              clearable
                                              v-model="selectedGroups">
                                </Autocomplete>
                            </v-col>
                        </template>
                        <template v-slot:item.templates_inspections="{ item }">
                            <v-simple-checkbox
                                @click="assignTemplate(item.id)"
                                :ripple="false"
                                v-if="!templatesInspectionsToValue(item.templates_inspections)"
                                :value="templatesInspectionsToValue(item.templates_inspections)"
                            ></v-simple-checkbox>
                            <v-simple-checkbox
                                :ripple="false"
                                v-else @click="unassignTemplate(item.id)"
                                :value="templatesInspectionsToValue(item.templates_inspections)"
                            ></v-simple-checkbox>
                        </template>
                    </DefaultTable>
                    </v-card>
                </template>
        </Modal>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';
import Modal from '@/components/templates/Modal';
import DefaultTable from '@/components/DefaultTable';
import employeesApi from '@/utils/api/employees';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import { mapGetters } from 'vuex';

export default {
    name: 'ManageAssignToEmployee',
    components: { HintingIcon, Modal, DefaultTable, Autocomplete },
    props: {
        id: {
            type: [String, Number],
            required: true
        },
        name: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        ICONS,
        dialog: false,
        selectedGroups: null,
        defaultParams: {
            'filter[is_item]': false,
            'sort': '-id'
        }
    }),
    computed: {
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        canRead () {
            return this.$can(this.$abilityActions.READ, this.$abilitySubjects.SUPER_ADMIN_AGENCY);
        },
        tableSetup () {
            return {
                searchBy: 'full_name',
                headers: [
                    {
                        text: 'No',
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: 'id',
                        value: 'id',
                        sortable: true
                    },
                    {
                        text: this.$t('labels.first_name'),
                        value: 'first_name',
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('labels.last_name'),
                        value: 'last_name',
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('navigation.position'),
                        value: 'position_name',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('inspections.summary.headers.template'),
                        value: 'templates_inspections',
                        icon: false,
                        width: '70px',
                        align: 'end',
                        sortable: false
                    }
                ],
                endpoint: 'employees',
                fetchParams: this.defaultParams,
                ability: {
                    c: false,
                    r: false,
                    u: false,
                    d: false
                },
                sortable: {
                    last_name: 'asc',
                    point_name: 'asc',
                    first_name: 'asc',
                    id: 'desc'
                }
            };
        },
        additionalParams () {
            if (this.selectedGroups) {
                return {
                    'filter[employee_group_id]': this.selectedGroups,
                    ...this.defaultParams
                };
            } else return null;
        }
    },
    methods: {
        templatesInspectionsToValue (templates) {
            if (templates) {
                return templates.includes(this.id);
            } else return false;
        },
        assignTemplate (employeeId) {
            const data = {
                data: {
                    id: this.id,
                    add_to_employee: true,
                    type: 'templates',
                    attributes: {
                        employee_id: employeeId
                    }
                }
            };
            return employeesApi.employeeSetAccessToTemplate(this.id, data)
                .then(() => {
                    this.$refs.employeesTable.fetchData();
                });
        },
        unassignTemplate (employeeId) {
            const data = {
                data: {
                    id: this.id,
                    add_to_employee: false,
                    type: 'templates',
                    attributes: {
                        employee_id: employeeId
                    }
                }
            };
            return employeesApi.employeeSetAccessToTemplate(this.id, data)
                .then(() => {
                    this.$refs.employeesTable.fetchData();
                });
        }

    },
    watch: {
        selectedGroups: {
            handler (val) {
                if (val) {
                    this.$refs.employeesTable.setup.fetchParams = this.additionalParams;
                    this.$refs.employeesTable.fetchData();
                } else {
                    this.$refs.employeesTable.setup.fetchParams = this.defaultParams;
                    this.$refs.employeesTable.fetchData();
                }
            },
            deep: true
        }
    }
};
</script>

<style scoped lang="scss">
    .customer-list {
        padding-left: 0;
        & li {
            list-style: none;
        }
    }
</style>
