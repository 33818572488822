var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableTitle',{staticClass:"mb-2",attrs:{"title":_vm.$t('labels.constant_questions'),"data-test":"TableTitle","button-visibility":_vm.showAddButton,"button-title":_vm.$t('buttons.add_new_question')},on:{"click":_vm.showQuestionForm}}),_c('DraggableTable',{attrs:{"items":_vm.constantQuestions,"with-pagination":false,"is-draggable":_vm.editable,"headers":_vm.headers},on:{"drag-update":function($event){return _vm.updateQuestionsList($event)}},scopedSlots:_vm._u([{key:"item.notifications",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'condition'))?_c('div',[_c('p',{staticClass:"my-1"},[_vm._v(_vm._s(_vm.$t('labels.condition'))+": "+_vm._s(_vm.conditionTitle(item)))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('labels.sms_confirmation_content'))+": "+_vm._s(item.smsContent))])]):_c('span')]}},{key:"item.parameters",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"break-word"},[_vm._v(_vm._s(_vm.parseParams(item.parameters)))])]}},{key:"item.repeated",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'repeated'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.repeated)))]):_c('span')]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'required'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.required)))]):_c('span')]}},{key:"item.notApplicable",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'notApplicable'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.notApplicable)))]):_c('span')]}},{key:"item.includeInScanField",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'includeInScanField'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.includeInScanField)))]):_c('span')]}},(_vm.editable)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditQuestion)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":"editIncidentBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.showQuestionForm(item)}}}):_vm._e(),(_vm.canRemoveQuestion)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteQuestion}}):_vm._e()]}}:null],null,true)}),_c('br'),_c('TableTitle',{staticClass:"mb-2",attrs:{"title":_vm.$t('labels.random_questions'),"button-visibility":false,"data-test":"TableTitle"}}),_c('DraggableTable',{attrs:{"items":_vm.randomQuestions,"with-pagination":false,"is-draggable":false,"headers":_vm.headers},on:{"drag-update":function($event){return _vm.updateQuestionsList($event)}},scopedSlots:_vm._u([{key:"item.sequence",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.randomQuestions.findIndex(function (el) { return el.id === item.id; }) + 1))])]}},{key:"item.notifications",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'condition'))?_c('div',[_c('p',{staticClass:"my-1"},[_vm._v(_vm._s(_vm.$t('labels.condition'))+": "+_vm._s(_vm.conditionTitle(item)))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('labels.sms_confirmation_content'))+": "+_vm._s(item.smsContent))])]):_c('span')]}},{key:"item.parameters",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"break-word"},[_vm._v(_vm._s(_vm.parseParams(item.parameters)))])]}},{key:"item.repeated",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'repeated'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.repeated)))]):_c('span')]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'required'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.required)))]):_c('span')]}},{key:"item.notApplicable",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'notApplicable'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.notApplicable)))]):_c('span')]}},{key:"item.includeInScanField",fn:function(ref){
var item = ref.item;
return [(_vm.showAnswer(item, 'includeInScanField'))?_c('span',[_vm._v(_vm._s(_vm.convertBoolean(item.includeInScanField)))]):_c('span')]}},(_vm.editable)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditQuestion)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":"editIncidentBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.showQuestionForm(item)}}}):_vm._e(),(_vm.canRemoveQuestion)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteQuestion}}):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }