<template>
    <ButtonWithConfirmation :item-id="id"
                            :action-button-title="archiveActionTitle"
                            :confirmation-text="confirmationText"
                            data-test="handleArchive"
                            @action-executed="$emit('fetch')"
                            v-if="canEditTemplate"
                            :callback="handleArchive">
        <HintingIcon :icon="archiveActionIcon"
                     :tooltip="archiveActionTitle"></HintingIcon>
        <slot>
            <span v-if="showText"
                  class="text--s font-weight-regular clickable ml-1">{{ archiveActionTitle }}</span>
        </slot>
    </ButtonWithConfirmation>
</template>

<script>
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';

export default {
    name: 'ManageArchiveTemplate',
    components: { ButtonWithConfirmation, HintingIcon },
    props: {
        archiveMode: {
            type: Boolean,
            'default': false
        },
        showText: {
            type: Boolean,
            'default': false
        },
        id: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        canEditTemplate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TEMPLATE);
        },
        archiveActionIcon () {
            return this.archiveMode ? ICONS.ARCHIVE_UP : ICONS.ARCHIVE_DOWN;
        },
        archiveActionTitle () {
            return this.archiveMode ? this.$t('actions.restore_from_archive') : this.$t('actions.archive');
        },
        confirmationText () {
            return this.archiveMode ? this.$t('messages.restore_archive_confirmation') : this.$t('messages.archive_confirmation');
        }
    },
    methods: {
        handleArchive () {
            const data = {
                id: this.id,
                data: {
                    archive: !this.archiveMode
                }
            };

            return this.$store.dispatch('manageTemplate/manageArchiveTemplate', data);
        }
    }
};
</script>

<style scoped>

</style>
