<template>
    <Modal :modal-title="modalTitle"
           :dialog="detailsVisibility"
           max-width="1600px"
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <v-layout align-center>
                <GetFile :loader="exportLoader"
                         :title="$t('buttons.export_template')"
                         @get-file="exportTemplate">
                    <template slot="icon">
                        <v-icon size="20"
                                class="mr-1">{{ ICONS.EXPORT }}</v-icon>
                    </template>
                </GetFile>
                <a v-if="canEditTemplate"
                   data-test="cloneBtn"
                   class="text--s font-weight-regular mx-4"
                   @click="openEditForm()">
                    <v-icon size="20"> {{ ICONS.COPY }}</v-icon>
                    {{ $t('buttons.clone_template') }}
                </a>
                <ManageArchiveTemplate :id="activeTemplate.id"
                                       @fetch="refreshActiveTemplatedata"
                                       :archive-mode="activeTemplate.archive"
                                       :show-text="true"></ManageArchiveTemplate>
            </v-layout>
        </template>
        <template slot="content">
            <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
            <v-row>
                <v-col md="8"
                       sm="12"
                       order="2"
                       order-sm="1"
                       cols="12">
                    <v-sheet color="white"
                             class="pa-4"
                             elevation="1">
                        <v-layout align-stretch
                                  :wrap="isMobile">
                            <v-flex sm6
                                    :class="{'bordered pr-4': smAndUp}">
                                <InformationItem :title="$t('labels.template_name')"
                                                 name="name"
                                                 :text="activeTemplate.name"></InformationItem>
                                <InformationItem :title="$t('labels.color')"
                                                 name="color">
                                    <template>
                                        <span class="color-example"
                                              :style="getCssVars(activeTemplate.color)"></span>
                                    </template>
                                </InformationItem>
                                <InformationItem :title="$t('labels.number_of_random_questions')"
                                                 name="number_of_random_questions"
                                                 :text="activeTemplate.number_of_random_questions"></InformationItem>
                            </v-flex>
                        </v-layout>
                    </v-sheet>
                </v-col>
                <v-col md="4"
                       sm="12"
                       order="1"
                       order-sm="2"
                       cols="12"
                       v-if="activeTemplate.special_attention">
                    <v-layout justify-end>
                        <span class="font-weight-bold red--text text--m">{{ $t('labels.special_attention') }}</span>
                    </v-layout>
                </v-col>
            </v-row>

            <QuestionsTable class="mt-8"
                            :editable="false"
                            :items="activeTemplate.questions"></QuestionsTable>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import Modal from '@/components/templates/Modal';
import QuestionsTable from '@/components/templatesConfigurations/QuestionsTable';
import InformationItem from '@/components/customers/InformationItem';
import GetFile from '@/components/widgets/buttons/GetFile';
import ManageArchiveTemplate from '@/components/templatesConfigurations/ManageArchiveTemplate';

export default {
    name: 'TemplateDetails',
    components: { Modal, QuestionsTable, InformationItem, GetFile, ManageArchiveTemplate },
    data: () => ({
        exportLoader: false,
        ICONS
    }),
    computed: {
        modalTitle () {
            return this.activeTemplate?.name || '';
        },
        ...mapState('manageTemplate', [
            'activeTemplate',
            'detailsVisibility'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        canEditTemplate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TEMPLATE);
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        getCssVars (color) {
            return {
                '--template-color': color
            };
        },
        openEditForm () {
            this.$store.commit('manageTemplate/CLONE_TEMPLATE_DATA', this.activeTemplate);
            this.$store.commit('manageTemplate/SET_TEMPLATE_FORM_VISIBILITY', true);
            this.$store.commit('manageTemplate/SET_CLONE_TEMPLATE', true);
        },
        exportTemplate () {
            this.exportLoader = true;
            return this.$store.dispatch('manageTemplate/exportTemplate')
                .finally(() => {
                    this.exportLoader = false;
                });
        },
        refreshActiveTemplatedata () {
            this.$store.dispatch('manageTemplate/getActiveTemplate', this.activeTemplate.id);
            this.$emit('fetch');
        }
    }
};
</script>

<style scoped lang="scss">
    .color-example {
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        background-color: var(--template-color);
    }
</style>
