<template>
    <Modal :modal-title="title"
           :dialog="templateFormVisibility"
           max-width="1600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageTemplateForm ref="form"></ManageTemplateForm>
            <QuestionsTable class="mt-8 pb-8"
                            :items="getAddedQuestions"></QuestionsTable>

            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>

            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="createTemplate">{{ $t('buttons.save') }}
                </v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageTemplateForm from '@/components/templatesConfigurations/manageTemplate/ManageTemplateForm';
import QuestionsTable from '@/components/templatesConfigurations/QuestionsTable';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageTemplate',
    components: { Modal, ManageTemplateForm, QuestionsTable, ConfirmationDialog, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        showError: false,
        loader: false
    }),
    computed: {
        ...mapState('manageTemplate', [
            'templateFormVisibility',
            'editedTemplateId',
            'detailsVisibility'
        ]),
        ...mapGetters('manageTemplate', [
            'getAddedQuestions'
        ]),
        title () {
            return this.isEditable
                ? this.$t('configurations.templates.edit_title')
                : this.$t('configurations.templates.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEditable () {
            return this.editedTemplateId !== null;
        }
    },
    methods: {
        close () {
            this.$store.commit('manageTemplate/SET_TEMPLATE_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('manageTemplate/SET_CLONE_TEMPLATE', false);
                if (!this.detailsVisibility) {
                    this.$store.dispatch('manageTemplate/clearActiveTemplateData');
                }
                this.showError = false;
            }, 100);
        },
        visibility (event) {
            this.$store.commit('manageTemplate/SET_TEMPLATE_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createTemplate () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.$store.dispatch('manageTemplate/createTemplate', this.editedTemplateId)
                            .then(({ data }) => {
                                if (this.detailsVisibility) {
                                    this.$store.dispatch('manageTemplate/getActiveTemplate', data.data.id);
                                }
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        handleConfirmation (event) {
            if (event) {
                this.createTemplate();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
