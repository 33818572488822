<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center>
            <v-flex>
                <template v-if="canImportData">
                    <ImportTemplate @fetch="emitFetch"></ImportTemplate>
                </template>
                <a class="d-inline-block ml-4"
                   @click="toggleArchiveMode">
                    <v-icon size="20">{{ toggleArchiveButtonIcon }}</v-icon>
                    {{ toggleArchiveButtonTitle }}
                </a>
            </v-flex>
            <v-flex>
                <v-layout justify-end
                          class="mb-8">
                    <v-btn color="secondary"
                           v-if="canAddTemplate"
                           data-test="addNewTemplateBtn"
                           @click="openEditForm()">{{ $t('buttons.add_new_template') }}
                    </v-btn>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="rawTemplates"
                      disable-pagination
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.color="{ item }">
                <v-layout align-center>
                    <span class="color-example"
                          :style="getCssVars(item.color)"></span>
                </v-layout>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.DETAILS"
                             data-test="showDetailsBtn"
                             @click="showDetails(item.id)"
                             :tooltip="$t('actions.view')"></HintingIcon>
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditTemplate"
                             @click="openEditForm(item.id)"
                             :data-test="item"
                             :tooltip="$t('actions.edit')"></HintingIcon>
                <ManageArchiveTemplate class-name="mr-2"
                                       v-if="canArchiveTemplate"
                                       :id="item.id"
                                       @fetch="emitFetch"
                                       :archive-mode="archiveMode"
                                       :data-test="item"></ManageArchiveTemplate>
                <ManageAssignToLocation class="ml-2"
                                        v-if="canEditTemplate || canEditLocation"
                                        type="templates"
                                        :id="item.id"></ManageAssignToLocation>
                <ManageAssignToEmployee class="ml-2"
                                        v-if="canEditTemplate || canEditEmployee"
                                        :id="item.id"
                                        :name="item.name"
                ></ManageAssignToEmployee>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <TemplateDetails v-if="editedTemplateId"
                         :dialog="detailsVisibility"
                         @fetch="emitFetch"
                         @visibility="detailsVisibility = $event"
                         @close="closeDetails"></TemplateDetails>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Pagination from '@/components/widgets/tables/Pagination';
import TemplateDetails from '@/components/templatesConfigurations/TemplateDetails';
import ImportTemplate from '@/components/templatesConfigurations/import/ImportTemplate';
import ManageArchiveTemplate from '@/components/templatesConfigurations/ManageArchiveTemplate';
import ManageAssignToLocation from '@/components/templatesConfigurations/assignToLocations/ManageAssignToLocation';
import ManageAssignToEmployee from '@/components/templatesConfigurations/ManageAssignToEmployee';
import ability from '@/mixins/ability';

export default {
    name: 'TemplatesConfigurationsTable',
    mixins: [ability],
    components: {
        SortableHeader,
        HintingIcon,
        Pagination,
        TemplateDetails,
        ImportTemplate,
        ManageArchiveTemplate,
        ManageAssignToLocation,
        ManageAssignToEmployee
    },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc',
            created_at: null,
            identification_number: 'asc'
        },
        ICONS,
        detailsVisibility: false,
        archiveMode: false
    }),
    computed: {
        ...mapState('templates', [
            'rawTemplates',
            'totalTemplatesAmount'
        ]),
        ...mapState('manageTemplate', [
            'editedTemplateId',
            'activeTemplate'
        ]),
        headers () {
            return [
                {
                    text: this.$t('labels.id'),
                    value: 'identification_number',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.color'),
                    value: 'color',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.template_name'),
                    value: 'name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.creation_date'),
                    value: 'created_at',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '180px',
                    sortable: false
                }
            ];
        },
        canEditTemplate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TEMPLATE);
        },
        canAddTemplate () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.TEMPLATE);
        },
        canArchiveTemplate () {
            return this.$can(this.$abilityActions.ARCHIVE, this.$abilitySubjects.TEMPLATE);
        },
        toggleArchiveButtonIcon () {
            return this.archiveMode ? ICONS.ARCHIVE_OUT : ICONS.ARCHIVE;
        },
        toggleArchiveButtonTitle () {
            return this.archiveMode ? this.$t('buttons.show_active') : this.$t('buttons.show_archived');
        },
        canEditEmployee () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.EMPLOYEE);
        },
        canEditLocation () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.LOCATION);
        }
    },
    watch: {
        totalTemplatesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        getCssVars (color) {
            return {
                '--template-color': color
            };
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        openEditForm (id = null) {
            if (id) {
                this.$store.dispatch('manageTemplate/getActiveTemplate', id)
                    .then(() => {
                        this.$store.commit('manageTemplate/CLONE_TEMPLATE_DATA', this.activeTemplate);
                        this.$store.commit('manageTemplate/SET_TEMPLATE_FORM_VISIBILITY', true);
                    });
            } else {
                this.$store.commit('manageTemplate/SET_TEMPLATE_FORM_VISIBILITY', true);
            }
        },
        showDetails (id) {
            this.$store.dispatch('manageTemplate/getActiveTemplate', id)
                .then(() => {
                    this.$store.commit('manageTemplate/SET_TEMPLATE_DATA', this.activeTemplate);
                    this.$store.commit('manageTemplate/SET_DETAILS_VISIBILITY', true);
                });
        },
        closeDetails () {
            this.$store.commit('manageTemplate/SET_DETAILS_VISIBILITY', false);
            this.$store.dispatch('manageTemplate/clearActiveTemplateData');
        },
        toggleArchiveMode () {
            this.archiveMode = !this.archiveMode;
            this.$emit('set-archive-mode', this.archiveMode);
        },
        showRowDetails (event, { item }) {
            this.showDetails(item.id);
        }
    },
    created () {
        const params = {
            'page[number]': -1
        };
        if (this.canEditTemplate) {
            this.$store.dispatch('employeesGroups/getEmployeesGroups', params);
        }
    }
};
</script>

<style scoped lang="scss">
.color-example {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: var(--template-color);
}
</style>
