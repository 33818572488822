<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.templates') }}</h1>
        <FiltersBar :with-filters="false"></FiltersBar>

        <TemplatesConfigurationsTable @change-page="changePage($event)"
                                      @change-amount="changeAmount($event)"
                                      @sort="sortingColumn = $event"
                                      @fetch="fetchTemplates"
                                      @set-archive-mode="changeArchiveMode($event)"
                                      :loader="loader"
                                      :changed-page="pagination.page"></TemplatesConfigurationsTable>

        <ManageTemplate @fetch="fetchTemplates"></ManageTemplate>
        <ManageQuestion></ManageQuestion>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapFields } from 'vuex-map-fields';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import TemplatesConfigurationsTable from '@/components/templatesConfigurations/TemplatesConfigurationsTable';
import ManageTemplate from '@/components/templatesConfigurations/manageTemplate/ManageTemplate';
import ManageQuestion from '@/components/templatesConfigurations/manageQuestion/ManageQuestion';

export default {
    name: 'TemplatesConfigurations',
    components: { FiltersBar, TemplatesConfigurationsTable, ManageTemplate, ManageQuestion },
    data: () => ({
        loader: false,
        pagination: {
            page: 1,
            amount: 20
        },
        sortingColumn: null,
        debouncedFetchTemplates: null,
        archiveFilter: false,
        currentFilter: true
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ])
    },
    watch: {
        sortingColumn: {
            handler () {
                this.pagination.page = 1;
                this.fetchTemplates();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchTemplates();
                }
            }
        }
    },
    methods: {
        fetchTemplates () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                'filter[archive]': this.archiveFilter,
                'filter[current]': this.currentFilter,
                'extra_fields[templates]': 'customers_ids,places_ids,points_ids'
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[search][match]'] = this.search;
            }

            this.$store.dispatch('templates/getTemplates', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchTemplates();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchTemplates();
        },
        changeArchiveMode (event) {
            this.archiveFilter = event;
            this.pagination.page = 1;
            this.fetchTemplates();
        }
    },
    created () {
        this.debouncedFetchTemplates = debounce(this.fetchTemplates, 1000);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.TEMPLATE)) {
                vm.fetchTemplates();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
