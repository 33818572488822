<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <v-layout>
                <v-flex class="px-4" sm4>
                    <TextField :label="$t('labels.template_name')"
                               name="name"
                               rules="required"
                               v-model="name"></TextField>
                </v-flex>
                <v-flex class="px-4" sm4>
                    <ColorPicker :label="$t('labels.color')"
                                 name="color"
                                 @clear="color = $event"
                                 v-model="color"></ColorPicker>
                </v-flex>
                <v-flex class="px-4" sm4>
                    <TextField :label="$t('labels.number_of_random_questions')"
                               name="nunumberOfRandomQuestions"
                               rules="required"
                               v-model="numberOfRandomQuestions"></TextField>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import ColorPicker from '@/components/widgets/forms/ColorPicker';

export default {
    name: 'ManageTemplateForm',
    components: { ValidationObserver, TextField, ColorPicker },
    computed: {
        ...mapFields('manageTemplate', [
            'templateData.name',
            'templateData.color',
            'templateData.numberOfRandomQuestions'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
