<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <v-layout align-stretch
                      :wrap="isMobile">
                <v-flex class="px-4" sm6>
                    <Autocomplete :label="$t('labels.question_type')"
                                  name="type"
                                  rules="required"
                                  :items="templateQuestionTypes"
                                  v-model="type"></Autocomplete>
                    <TextField :label="$tc('labels.question', 1)"
                               name="question"
                               rules="required"
                               v-model="question"></TextField>
                    <MultiText :label="$t('labels.question_parameters')"
                               rules="required"
                               v-if="getFormParameters.parameters"
                               name="parameters"
                               v-model="parameters"></MultiText>
                    <FieldsetGroup :label="$t('labels.notifications')"
                                   v-if="getFormParameters.condition">
                        <template slot="content">
                            <RadioGroup :label="$t('labels.condition')"
                                        name="radioCondition"
                                        v-if="showRadioCondition"
                                        :options="radioConditionParams"
                                        v-model="condition"></RadioGroup>
                            <TextField :label="$t('labels.condition')"
                                       name="textCondition"
                                       v-else
                                       rules="integer"
                                       :placeholder="conditionPlaceholder"
                                       v-model="condition"></TextField>
                            <TextField :label="$t('labels.sms_confirmation_content')"
                                       name="smsContent"
                                       :disabled="!condition"
                                       v-model="smsContent"></TextField>
                        </template>
                    </FieldsetGroup>
                </v-flex>
                <v-flex class="px-4" sm6>
                    <Autocomplete :label="$t('labels.incident_type')"
                                  v-if="getFormParameters.incident"
                                  name="incident"
                                  clearable
                                  :items="filterableTypesList"
                                  :loading="isTypesLoading"
                                  :search-input.sync="searchTypes"
                                  @click:clear="getIncidentsTypes"
                                  @load-more-items="getIncidentsTypesLazyLoading"
                                  v-model="incident"></Autocomplete>
                    <Checkbox :label="$t('labels.repetitive_question')"
                              name="repeated"
                              v-if="getFormParameters.repeated"
                              class-name="mt-0"
                              v-model="repeated"></Checkbox>
                    <Checkbox :label="$t('labels.required_question')"
                              name="required"
                              v-if="getFormParameters.required"
                              class-name="mt-0"
                              v-model="required"></Checkbox>
                    <Checkbox :label="$t('labels.random')"
                              name="random"
                              v-if="getFormParameters.random"
                              class-name="mt-0"
                              v-model="random"></Checkbox>
                    <Checkbox :label="$t('labels.not_applicable')"
                              v-if="required && getFormParameters.notApplicable"
                              name="notApplicable"
                              class-name="mt-0"
                              v-model="notApplicable"></Checkbox>
                    <Checkbox :label="$t('labels.include_in_scan_field')"
                              v-if="getFormParameters.includeInScanField"
                              name="includeInScanField"
                              class-name="mt-0"
                              v-model="includeInScanField"></Checkbox>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { uuid } from 'uuidv4';
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapState } from 'vuex';
import { TYPES } from '@/constants/questionTypes';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import MultiText from '@/components/widgets/forms/MultiText';
import FieldsetGroup from '@/components/widgets/forms/FieldsetGroup';
import RadioGroup from '@/components/widgets/forms/RadioGroup';
import Checkbox from '@/components/widgets/forms/Checkbox';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';

export default {
    name: 'ManageQuestionForm',
    mixins: [debouncedIncidentTypes],
    components: { ValidationObserver, TextField, Autocomplete, MultiText, FieldsetGroup, RadioGroup, Checkbox },
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        TYPES
    }),
    computed: {
        ...mapFields('manageTemplateQuestion', [
            'questionData.sequence',
            'questionData.type',
            'questionData.typeName',
            'questionData.question',
            'questionData.id',
            'questionData.parameters',
            'questionData.condition',
            'questionData.smsContent',
            'questionData.incident',
            'questionData.incidentName',
            'questionData.repeated',
            'questionData.required',
            'questionData.random',
            'questionData.notApplicable',
            'questionData.includeInScanField'
        ]),
        ...mapGetters('manageTemplateQuestion', [
            'getFormParameters'
        ]),
        ...mapState('manageTemplateQuestion', [
            'questionFormVisibility'
        ]),
        ...mapState('templates', [
            'templateQuestionTypes'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        ...mapGetters('manageTemplate', [
            'getAddedQuestions'
        ]),
        conditionPlaceholder () {
            return this.type === TYPES.MULTIPLE_CHOICE.toString() ? this.$t('labels.min_checked_number') : this.$t('labels.min_value');
        },
        showRadioCondition () {
            return this.type === TYPES.YES_NO.toString();
        },
        radioConditionParams () {
            return [
                {
                    text: this.$t('answers.yes'),
                    value: 1
                },
                {
                    text: this.$t('answers.no'),
                    value: 2
                }
            ];
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        }
    },
    watch: {
        type: {
            handler (val, oldVal) {
                if (val) {
                    this.typeName = this.templateQuestionTypes.find(el => el.value === val).text;
                }

                if (val !== oldVal && oldVal !== null) {
                    this.$store.commit('manageTemplateQuestion/SET_DATA_DEFAULT_VALUES');
                }

                if (val === TYPES.INFORMATION.toString() || val === TYPES.HEADER.toString()) {
                    this.required = false;
                }
            }
        },
        incident: {
            handler (val) {
                this.incidentName = val ? this.filterableTypesList.find(el => el.value === val).text : null;
            }
        },
        required: {
            handler (val) {
                if (!val) {
                    this.notApplicable = false;
                }
            }
        },
        questionFormVisibility: {
            handler (val) {
                if (val) {
                    this.$store.dispatch('templates/getQuestionTypes');
                    this.$store.dispatch('incidents/getIncidentTypes');
                    if (!this.isEditable) {
                        this.setInitialValues();
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        },
        setInitialValues () {
            this.id = uuid();
            this.sequence = this.getAddedQuestions.filter(el => !el.random).length + 1;
        }
    }
};
</script>

<style scoped>

</style>
